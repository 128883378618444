import { Link } from "gatsby"
import React from "react"

import "./Header.css"

const Header = () => {
  return (
    <header className="header">
      <h1
        style={{
          margin: 0,
        }}
      >
        <Link
          to="/"
          style={{
            color: `var(--color-second)`,
            textDecoration: `none`,
          }}
        >
          carton
          <span
            style={{
              color: `var(--color-light)`,
              fontSize: `1rem`,
              paddingBottom: `.5rem`,
            }}
          >
            .pm
          </span>
        </Link>
      </h1>
      {/* <Nav /> */}
    </header>
  )
}

export default Header
